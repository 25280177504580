// Font-Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

@import "~select2/dist/css/select2.css";

// Dark mode for Flatpickr
@import "~flatpickr/dist/flatpickr.css" screen;
@import "~flatpickr/dist/themes/dark.css" screen and (prefers-color-scheme: dark);
