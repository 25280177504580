@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'datatables.net-dt/css/jquery.dataTables';

@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

body {
    font-family: 'Lato', sans-serif;
}

.limContainer {
    @apply px-5 sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto;
}
.redLink {
    @apply underline hover:no-underline text-red-500 dark:text-red-300 hover:text-red-800 hover:dark:text-red-600 ease-in-out duration-200;
}

[x-cloak] {
    display: none !important;
}

.turbo-progress-bar {
    @apply bg-red-500;
}

/* Toggle */
.toggle-checkbox, .toggle-label {
    @apply transition ease-linear duration-100;
}
.toggle-checkbox:checked {
    @apply right-0 border-green-600;
}
.toggle-checkbox:checked + .toggle-label {
    @apply bg-green-600;
}

/* Select2 Theming */
.select2-container--default .select2-selection--single, .select2-container--default .select2-selection--multiple {
    @apply rounded-lg border-gray-300 dark:border-gray-400 dark:bg-neutral-500 min-h-[42px];
}

.select2-container .select2-search--inline .select2-search__field {
    @apply align-top mt-[9px] ml-[12px] text-sm tracking-wide;
}
.select2-container--default .select2-selection--multiple {
    @apply pt-[2px];
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    @apply leading-6 pl-3 py-2 dark:text-white;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
    @apply dark:bg-neutral-700 dark:border-neutral-600;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
    @apply border-gray-400;
}
.select2-selection__arrow {
    @apply mr-[10px] top-2 !important;
}
.select2-dropdown {
    @apply dark:border-gray-400 dark:bg-neutral-500 dark:text-white;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
    @apply focus:border-sky-500 focus:dark:border-cyan-500 focus:ring focus:ring-opacity-20 focus:dark:ring-opacity-10 focus:ring-sky-300 focus:dark:ring-cyan-100 dark:bg-neutral-600 dark:text-white rounded-md;
}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    @apply bg-red-700 text-white;
}
.select2-container--default .select2-results__option--selected {
    @apply bg-neutral-300 dark:bg-neutral-700 dark:text-white;
}
.select2-container--open {
    z-index: 9999999
}
/* Flash Coloring */
.flash-info {
    @apply bg-blue-600 text-white px-5 py-3 rounded-lg shadow-blue-200 dark:shadow-blue-800 shadow-md;
}
.flash-success {
    @apply bg-green-600 text-white px-5 py-3 rounded-lg shadow-green-200 dark:shadow-green-800 shadow-md;
}
.flash-warning {
    @apply bg-orange-600 text-white px-5 py-3 rounded-lg shadow-orange-200 dark:shadow-orange-800 shadow-md;
}
.flash-error {
    @apply bg-red-600 text-white px-5 py-3 rounded-lg shadow-red-200 dark:shadow-red-800 shadow-md;
}

/* Torchlight */
pre {
    @apply my-4 rounded-lg overflow-x-auto;
}
pre code.torchlight {
    @apply block py-4 min-w-max;
}
pre code.torchlight .line {
    @apply px-4;
}
pre code.torchlight .line-number,
pre code.torchlight .summary-caret {
    @apply mr-4;
}
